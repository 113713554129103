#client-avatar-container {
    padding: 20px;
    display: flex;
}

#client-avatar-label {
    margin-left: 10px;
    margin-top: 10px;
    color: grey;
    float: left;
    font-size: small;
}
