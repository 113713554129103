/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */
@import url(//fonts.googleapis.com/css?family=Open+Sans);

/** Ionic CSS Variables **/
:root {
    /** primary **/
    --ion-color-primary: #3880ff;
    --ion-color-primary-rgb: 56, 128, 255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #3171e0;
    --ion-color-primary-tint: #4c8dff;

    /** secondary **/
    --ion-color-secondary: #3dc2ff;
    --ion-color-secondary-rgb: 61, 194, 255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #36abe0;
    --ion-color-secondary-tint: #50c8ff;

    /** tertiary **/
    --ion-color-tertiary: #5260ff;
    --ion-color-tertiary-rgb: 82, 96, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #4854e0;
    --ion-color-tertiary-tint: #6370ff;

    /** success **/
    --ion-color-success: #2dd36f;
    --ion-color-success-rgb: 45, 211, 111;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #28ba62;
    --ion-color-success-tint: #42d77d;

    /** warning **/
    --ion-color-warning: #3880ff;
    --ion-color-warning-rgb: 55, 196, 9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #3880ff;
    --ion-color-warning-tint: #3880ff;

    /** danger **/
    --ion-color-danger: #eb445a;
    --ion-color-danger-rgb: 235, 68, 90;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #cf3c4f;
    --ion-color-danger-tint: #ed576b;

    /** dark **/
    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 36, 40;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;

    /** medium **/
    --ion-color-medium: #92949c;
    --ion-color-medium-rgb: 146, 148, 156;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;

    /** light **/
    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 245, 248;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;

    --ion-background-color: #ffffff;
    --ion-background-color-rgb: 255, 255, 255;

    --ion-text-color: rgb(90, 88, 88);
    --ion-text-font: 'text-custom';
    --ion-text-color-rgb: 0, 0, 0;
    --ion-font-weight: 'light';

    --ion-color-step-50: #f2f2f2;
    --ion-color-step-100: #e6e6e6;
    --ion-color-step-150: #d9d9d9;
    --ion-color-step-200: #cccccc;
    --ion-color-step-250: #bfbfbf;
    --ion-color-step-300: #b3b3b3;
    --ion-color-step-350: #a6a6a6;
    --ion-color-step-400: #999999;
    --ion-color-step-450: #8c8c8c;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #737373;
    --ion-color-step-600: #666666;
    --ion-color-step-650: #595959;
    --ion-color-step-700: #4d4d4d;
    --ion-color-step-750: #404040;
    --ion-color-step-800: #333333;
    --ion-color-step-850: #262626;
    --ion-color-step-900: #191919;
    --ion-color-step-950: #0d0d0d;

    --ion-item-background: #ffffff;

    --ion-tab-bar-background: #3880ff;

    --ion-card-background: #ffffff;

    --ion-icon-color: blue;
}

@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 100;
    src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUOjIg1_i6t8kCHKm459WxZqh7p29NNpQ.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

.color-text {
    color: grey;
}

.content {
    padding: 3% 3% 0 3%;
    color: grey;
}

.input {
    background: transparent;
    width: 100%;
    border: none;
    padding: 0 15px;
    font-size: small;
    color: #0d0d0d;
}

.input:focus-visible {
    outline: none;
}

.list {
    padding: 0;
    margin: -3% 0 2% 0;
    border: 1px solid rgba(212, 205, 212, 0.5);
    overflow: auto;
}

.modal {
    padding: 0;
    margin: -3% 0 2% 0;
    border: 1px solid rgba(212, 205, 212, 0.5);
    overflow: auto;
}

.ion-item1 {
    font-family: 'Montserrat';
    font-size: small;
    --ion-text-color: rgb(90, 88, 88);
    color: grey;
}

.ion-item {
    font-family: 'Montserrat';
    font-size: small;
    margin-left: auto;
    --ion-text-color: rgb(90, 88, 88);
    color: grey;
}

.ion-item-balance {
    font-family: 'Montserrat';
    font-size: small;
    margin-left: auto;
    --ion-text-color: rgb(90, 88, 88);
    color: green;
}
.page-background-color {
    background: white;
}

.balance {
    color: green;
}

.ion-title {
    font-family: 'Montserrat';
    font-size: medium;
    margin-right: auto;
    color: grey;
}

.receipt-item-icon {
    padding: 0;
    margin: auto;
    font-size: small;
    font-family: 'Montserrat', sans-serif;
    font-weight: 1px;
}

.ion-title-top {
    font-family: 'Montserrat';
    font-size: medium;
}

.checkbox {
    font-family: 'Montserrat';
    font-size: medium;
    margin-left: 80%;
    color: grey;
}

.checkbox1 {
    font-family: 'Montserrat';
    color: grey;
}

.icon {
    font-family: 'Montserrat';
    color: grey;
    size: 20px;
}

.ion-padding {
    font-family: 'Montserrat';
    margin: auto;
    margin-left: 5%;
}
