.data-bill {
    border: 1px solid #f3f3f3;
}

.amount {
    caret-color: var(--ion-color-primary);
    font-size: 2em;
}

.cash-icon {
    font-size: 2.2em;
    font-weight: bolder;
    text-align: right;
    align-self: center;
    color: var(--ion-color-primary);
}
.data-column {
    color: var(--ion-color-danger);
    text-align: right;
    font-size: 0.7em;
}

.title-column {
    font-size: 0.8em;
}

.title-pay {
    font-weight: bold;
}

.separation {
    border-top: 1px solid #e9e9e9;
}

.table-receipts {
    border: 1px solid #e9e9e9;
}

.labels {
    font-size: 0.6em;
}

.first-row-receipt {
    font-size: 0.8em;
}

.sec-row-receipt {
    font-size: 0.7em;
}

.receipt-col > .sec-row-receipt {
    justify-content: flex-end;
    padding-right: 0.5em;
}
.receipt-col > .first-row-receipt {
    justify-content: flex-end;
    padding-right: 0.5em;
}

.selected {
    font-size: 0.7em;
}

.client-inf {
    text-align: left;
}

.pay-details {
    margin-bottom: 70px;
}