.SpinnerContent {
    position: absolute;
    margin-top: -7px;
    margin-left: 7px;
}

#last-movements-title {
    padding: 0 10px;
    font-size: 12px;
}

.noReceipt {
    text-align: center;
    margin-top: 50px !important;
}
