.receipt-item-container {
    font-size: smaller;
    display: flex !important;
    margin: 0;
    padding: 0;
    cursor: pointer;
}

.receipt-item-icon {
    padding: 0;
    margin: auto;
    border: 2px;
    font-family: 'Montserrat', sans-serif;
}

.receipt-item-data {
    padding: 0;
    margin: auto auto auto 10px;
    border: none;
}

.receipt-item-date {
    text-align: right;
    padding: 0;
    margin: auto auto auto 10px;
    border: none;
}
