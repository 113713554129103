.SpinnerContent {
    position: absolute;
    margin-top: -7px;
    margin-left: 7px;
}

#client-title-container {
    display: flex;
    justify-content: space-between;
    color: grey;
    padding: 0 10px;
}

#last-accounting-title {
    font-size: 12px;
}

#checkbox-container {
    display: flex;
    justify-content: space-between;
    color: grey;
    font-size: 12px;
    padding: 0 10px;
}

#checkbox {
    margin-right: 15px;
    margin-top: 1px;
}

.bill_modal {
    padding: 5% 5% 0% 5%;
}

.close_future {
    margin-left: 10%;
    size: 2px;
}

ion-fab-list ion-fab-button[title]::after {
    position: absolute;
    top: 4px;
    right: 45px;

    color: white;
    background-color: rgba(0,0,0,0.7);
    line-height: 24px;
    padding: 4px 8px;
    border-radius: 4px;

    content: attr(title);
    z-index: 1;
}
