@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 80;
    src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUOjIg1_i6t8kCHKm459WxZqh7p29NNpQ.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

.workspace-title {
    font-size: small !important;
    font-family: 'Montserrat';
    color: 'text.secondary';
    margin-left: 0%;
    border-color: transparent;
}

.workspace-content {
    font-size: small !important;
    font-family: 'Montserrat', sans-serif;
    color: 'grey';
    font-weight: 80;
    background-color: pink;
    border-color: transparent;
}

.workspace-button {
    font-size: xx-small !important;
    font-family: 'Montserrat', sans-serif;
    color: 'text.secondary';
    font-weight: 80;
    margin-left: 1%;
}

.option {
    background-color: white;
    color: gray;
}
